.container{
    .updateHeader{
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin-bottom: 20px;
        column-gap: 20px;
        background-color: aliceblue;
        padding: 10px;
        border-radius: 10px;
        .updateContainer{
            padding: 10px;
            background-color: green;
            color: white;
            border-radius: 10px;
            cursor: pointer;
        }
        .updateContainer:nth-last-child(1){
            background-color: blue;
            cursor: pointer;
        }
        .updateTitle{
            font-size: 17px;
            font-weight: 600;
            cursor: pointer;
        }
    }
  
}