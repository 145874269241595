.selectionContainer {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    flex-wrap: wrap;
    .selectionDiv {
        display: flex;
        justify-content: center;
        background-color: aliceblue;
        flex-direction: column;
        padding: 10px;
        border-radius: 10px;
        margin-right: 10px;
        margin-bottom: 10px;
        row-gap: 10px;
        .selectionTitle {
            font-size: 17px;
            font-weight: 600;
        }
        .masterInput {
            border: none;
            border-bottom: 1px solid black;
            padding: 5px;
            border-radius: 5px;
            box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
            height: 35px;
        }
    }
}
.innerSelection {
    display: flex;
    column-gap: 20px;
    flex-direction: column;
    .updateButton {
        background-color: green;
        color: white;
        padding: 10px;
        border-radius: 10px;
        cursor: pointer;
        text-align: center;
        margin-top: 10px;
        border: none;
        font-size: 17px;
    }
    .Scan {
        background-color: blue;
        color: white;
        padding: 10px;
        border-radius: 10px;
        cursor: pointer;
        text-align: center;
        margin-top: 10px;
        border: none;
        font-size: 17px;
    }
}
.manual {
    margin-top: 30px;
}
.selectedPackages {
    display: flex;
    margin-top: 40px;
    flex-wrap: wrap;
    flex-direction: row;
    .innerOne {
        display: flex;
        column-gap: 20px;
        flex-wrap: wrap;
        row-gap: 20px;
        > div {
            padding: 10px;
            border-radius: 10px;
            background-color: aliceblue;
            font-weight: 600;
            cursor: pointer;
        }
    }
}
.modificationDiv {
    margin-top: 20px;
    > p:nth-child(1) {
        font-size: 17px;
        font-weight: 600;
    }
}
.mostInnerContainer {
    display: flex;
    flex-wrap: wrap;
    margin-top: 20px;
    background-color: aliceblue;
    padding: 10px;
    border-radius: 10px;
    column-gap: 20px;
    row-gap: 20px;
    .mostInner {
        display: flex;
        flex-direction: column;
        width: 300px;
        > label {
            font-size: 14px;
            font-weight: 600;
        }
        > input {
            border: none;
            border-bottom: 1px solid black;
            padding: 5px;
            border-radius: 5px;
            box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
            height: 35px;
            margin-top: 10px;
            border: 1px solid #cecece;
        }
        > select {
            border: none;
            border-bottom: 1px solid black;
            padding: 5px;
            border-radius: 5px;
            box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
            height: 35px;
            margin-top: 10px;
            border: 1px solid #cecece;
        }
    }
}
.updateButtonContainer {
    display: flex;
    justify-content: center;
    margin-top: 50px;
    .updateButton {
        background-color: green;
        color: white;
        padding: 10px;
        border-radius: 10px;
        cursor: pointer;
        text-align: center;
        margin-top: 10px;
        border: none;
        font-size: 17px;
        padding: 10px 100px;
    }
}
.cameraDiv {
    display: flex;
    justify-content: center;
    background-color: aliceblue;
    padding: 10px;
    margin-top: 10px;
    border-radius: 10px;
}

.flightInput {
    border: none;
    border-bottom: 1px solid black;
    padding: 5px;
    border-radius: 5px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    height: 35px;
    margin-top: 10px;
    border: 1px solid #cecece;
}
